import request from '../utils/request.js';

// PC登陆二维码
export function getCodeunique() {
	return request({
		url: '/api/User/code_unique',
		method: 'get'
	})
}

// PC 微信检测是否登录
export function islogin(params) {
	return request({
		url: '/api/User/islogin',
		method: 'get',
		params
	})
}

// 抖音登陆二维码
export function tiktokUnique(params) {
	return request({
		url: '/api/User/tiktok_unique',
		method: 'get',
		params
	})
}

// 抖音获取用户信息
export function tiktokUser(params) {
	return request({
		url: '/api/User/tiktok_user',
		method: 'get',
		params
	})
}

// 导航节点
export function getNav() {
	return request({
		url: '/api/Wwhcommon/nav',
		method: 'get',
	})
}

// 公共数据
export function getCommon() {
	return request({
		url: '/api/Wwhcommon/common',
		method: 'get'
	})
}

// 首页banner
export function getHomeBanner() {
	return request({
		url: 'api/Wwhcommon/home_banner',
		method: 'get'
	})
}

// 首页内容
export function getHome() {
	return request({
		url: '/api/Wwhcommon/home',
		method: 'get'
	})
}

// 产品列表
export function getProduct(params) {
	return request({
		url: '/api/Wwhcommon/product',
		method: 'get',
		params
	})
}

// 产品详情
export function getProductDetails(params) {
	return request({
		url: '/api/Wwhcommon/product_details',
		method: 'get',
		params
	})
}

// 产品推荐 
export function getProductRecommend() {
	return request({
		url: '/api/Wwhcommon/product_Recommend',
		method: 'get'
	})
}

// 关于我们
export function getAboutUs() {
	return request({
		url: '/api/Wwhcommon/about_us',
		method: 'get'
	})
}

// 产品分类
export function getProductcategory() {
	return request({
		url: '/api/Wwhcommon/productcategory',
		method: 'get'
	})
}

// 新闻分类 
export function getNewsclassification() {
	return request({
		url: '/api/Wwhcommon/newsclassification',
		method: 'get'
	})
}

// 新闻列表 
export function getNewslist(params) {
	return request({
		url: '/api/Wwhcommon/newslist',
		method: 'get',
		params
	})
}

// 新闻详情 
export function getNewsdetails(params) {
	return request({
		url: '/api/Wwhcommon/newsdetails',
		method: 'get',
		params
	})
}

// 联系我们
export function getContactUs() {
	return request({
		url: '/api/Wwhcommon/Contact_Us',
		method: "get"
	})
}

export function getList(query) {
	return request({
		url: '/system/api/product/list',
		method: 'get',
		params: query
	})
}
export function getListDetail(query) {
	return request({
		url: '/system/api/product/' + query,
		method: 'get',
		// params: query
	})
}

export function aboutmine(query) {
	return request({
		url: '/system/api/company/list',
		method: 'get',
		params: query
	})
}

export function newslist(query) {
	return request({
		url: '/system/api/news/list',
		method: 'get',
		params: query
	})
}

export function newsDetail(query) {
	return request({
		url: '/system/api/news/' + query,
		method: 'get',
		// params: query
	})
}

export function productlist(query) {
	return request({
		url: '/system/api/product/list',
		method: 'get',
		params: query
	})
}

export function companynews(query) {
	return request({
		url: '/system/api/company/list',
		method: 'get',
		params: query
	})
}

export function loginQR(query) {
	return request({
		url: '/system/api/champig/user/getMpQrCode ',
		method: 'get',
		params: query
	})
}


export function logincall(query) {
	return request({
		url: '/system/api/champig/user/getSceneId?sceneId=' + query,
		method: 'get',
		// params: query
	})
}

export function scanLogin(query) {
	return request({
		url: '/system/api/champig/user/scanLogin',
		method: 'get',
		params: query
	})
}

export function getorder(data) {
	return request({
		url: '/system/api/order/addPcOrder',
		method: 'post',
		data: data
	})
}

export function getcodedy(data) {
	return request({
		url: '/system/api/champig/user/getDyOpenCode',
		method: 'get',
		data: data
	})
}


// 询价
export function postquotation(data) {
	return request({
		url: '/api/Inquiry/quotation',
		method: 'post',
		data:data
	})
}
// 询价列表
export function getxunlie(data) {
	return request({
		url: '/api/Inquiry/list',
		method: 'get',
		params:data
	})
}
// 询价详细
export function getxunxiang(data) {
	return request({
		url: '/api/Inquiry/details',
		method: 'get',
		params:data
	})
}

// 公司动态
export function getApplet(data) {
	return request({
		url: '/api/Applet/organizational_updates',
		method: 'get',
		params:data
	})
}
//动态点赞
export function getthumbs(data) {
	return request({
		url: '/api/Applet/thumbs',
		method: 'get',
		params:data
	})
}


//网络地址
export function wangzhi() {
	return request({
		url: '/api/index/config',
		method: 'get',
		// params:data
	})
}