import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './utils/request';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'YtQGRKasczUBVMLraujbnSzGv1ju57N0'
});

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

Vue.config.productionTip = false
Vue.prototype.$request=request;
Vue.use(ElementUI)

new Vue({
 ...App,
  // render: h => h(App),
  router,
  store,
}).$mount('#app')
